import { useState } from "react";
import { Modal, Button} from "antd";
import { MapControl } from '../map/MapControl'
import { CheckOutlined, ConsoleSqlOutlined, ExclamationOutlined } from "@ant-design/icons";


const MapModal = ({ showModal, setShowModal, location, setLocation, zoom, setZoom }) => {
  const [minZoom] = useState(1);
  const [maxZoom] = useState(12);
  const [zoom2, setZoom2] = useState(1);
  const [center, setCenter] = useState([location.coordinates[1],location.coordinates[0]]);

  const handleClick = ({ event, latLng, pixel }) => {
    console.log('home page Map clicked!', latLng, pixel)
    setCenter(latLng);
    setLocation({
      coordinates: latLng
    });
  }
  
  const handleContextClick = ({ event, latLng, pixel }) => {
    event.preventDefault();
    setCenter(latLng);
    console.log('home page Map clicked Context!', latLng, pixel)
  }
  
  const handleMarkerClick = (event, payload, anchor) => {
    console.log(`Home page Marker #${payload} clicked at: `, anchor)
  }
  
  const onDone = () => {
    setShowModal(!showModal);
  }
 
  
  const handleMarkerContextClick = (event, payload, anchor) => {
    event.preventDefault();
    console.log(`Home page Marker context #${payload} clicked at: `, anchor)
  }

  const handleBoundsChange = ({ center, zoom, bounds, initial }) => {
    if (initial) {
      console.log('Got initial bounds: ', bounds);
      console.log('Got initial bounds zoom: ', zoom);
      console.log('Got initial bounds center: ', center);
    }
    if (center) {
      setLocation({
        coordinates: center
      });
    } else {
      setLocation({
        coordinates: [ 
          (bounds.ne[1] - bounds.sw[1]) / 2 + bounds.sw[1],
          (bounds.ne[0] - bounds.sw[0]) / 2 + bounds.sw[0], 
        ]
      }); 
    }
    setZoom(zoom);
    setZoom2(zoom);
  }

  
  return (
    <Modal
      visible={showModal}
      title="Search Location"
      onCancel={() => setShowModal(!showModal)}
    >
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        <div style={{ maxWidth: 600, width: '98%', height: 350, margin: '0 auto' }}>
          Zoom to search within map area shown
          <MapControl
              onClick={handleClick}
              onContextMenu={handleContextClick}
              onVehicleClick={handleMarkerClick}
              onMarkerClick={handleMarkerClick}
              onMarkerContextClick={handleMarkerContextClick}
              center={center}
              zoom={zoom}
              minZoom={minZoom}
              maxZoom={maxZoom}
              defaultWidth={600}
              defaultHeight={600}
              height={300}
              boxClassname="pigeon-filters"
              onBoundsChanged={handleBoundsChange}
              >
          </MapControl>
          {(zoom2 >= maxZoom - 3) ? (
            < Button type="primary" style={{ width: "3em", height: "2em", position: "absolute", margin: "0 0 0 -1rem" }} onClick={onDone}><CheckOutlined style={{ position: "absolute", height: "2em", margin: "-8px 0 0 -6px" }} /></Button >
          ) : (< Button type="danger" style={{ width: "10em", height: "2em", position: "absolute", margin: "0 0 0 -4rem" }}> Zoom in</Button >)
          }
        </div>
      </div>
    </Modal>
  );
};

export default MapModal;
