import axios from "axios";

export const createConnectAccount = async (token) =>
  await axios.post(
    `${process.env.REACT_APP_API}/create-connect-account`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getAccountStatus = async (token) =>
  axios.post(
    `${process.env.REACT_APP_API}/get-account-status`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getAccountBalance = async (token) =>
  axios.post(
    `${process.env.REACT_APP_API}/get-account-balance`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const currencyFormatter = (data, showZreo) => {
  return  (data.amount > 0 || showZreo) ? (data.amount / 100).toLocaleString(data.currency, {
    style: "currency",
    currency: data.currency,
  }) : (data.amount) ? "Inactive" : "Free" ;
};

export const payoutSetting = async (token) =>
  await axios.post(
    `${process.env.REACT_APP_API}/payout-setting`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getSessionId = async (token, hotelId) =>
  await axios.post(
    `${process.env.REACT_APP_API}/stripe-session-id`,
    {
      hotelId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const stripeSuccessRequest = async (token, hotelId) =>
  await axios.post(
    `${process.env.REACT_APP_API}/stripe-success`,
    { hotelId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  
export const purchaseForFree = async (token, tourId, couponId) =>
    await axios.post(
      `${process.env.REACT_APP_API}/purchase-for-free`,
      {
        tourId: tourId,
        couponId: couponId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
      },
    }
  );
