import React, { useState } from "react";
import { Select, Input, Tooltip } from "antd";
import { EnvironmentOutlined, SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import MapModal from "../modals/MapModal";

// destructure values from ant components
const { Option } = Select;

const Search = () => {
  // state
//  const [location, setLocation] = useState({coordinates:[25.07880447961632, -77.34040652042745]});
  const [location, setLocation] = useState({ coordinates: [0, 0] });
  const [locationInit] = useState({ coordinates: [0, 0] });
//  const [zoom, setZoom] = useState(1);
  const [zoom2, setZoom2] = useState(1);
  const [storageType, setStorageType] = useState("");
  const [duration, setDuration] = useState("");
  const [showModal, setShowModal] = useState(false);
  // route
  const history = useHistory();
  const handleSubmit = () => {
    if (!(location && location.coordinates && location.coordinates.length === 2 && location.coordinates[0] && location.coordinates[1])) {
      toast.error("A location is required");
    } else
//      console.log(`/search-result?lat=${location.coordinates[1]}&lng=${location.coordinates[0]}&duration=${duration}&storageType=${storageType}&zoomScale=${zoom2}`);
      history.push(`/search-result?lat=${location.coordinates[1]}&lng=${location.coordinates[0]}&duration=${duration}&storageType=${storageType}&zoomScale=${zoom2}`);
  };


  const showMapPicker = () => {
    setShowModal(true);
    console.log("Zoom2:" + zoom2);
  };



  function updatePosition(position) {
    setLocation({ coordinates: [position.coords.longitude, position.coords.latitude] });
  }

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(updatePosition);
    } else {
      document.getElementById("noGPS").innerHTML = "GPS / Geolocation is disabled or not supported by this browser.";
      document.getElementById("logoHeader").style.display = "block";
    }
  };
  

  return (
  <>
    <div className="d-flex pb-4">
        <span className="w-25 ">&nbsp;</span>
        <div className="w-100 ">

          {
            showModal && (
              <MapModal
                setShowModal={setShowModal}
                showModal={showModal}
                zoom={1}                
                setZoom={setZoom2}
                location={locationInit}
                setLocation={setLocation}
              />
            )
          }

          <Input onClick={showMapPicker}
            className="form-control w-100"
            style={{ height: "3.1em" }}
            size="large"
            placeholder={(location && location.coordinates && location.coordinates.length === 2 && location.coordinates[0] && location.coordinates[1]) ? `${location.coordinates[1]}, ${location.coordinates[0]}` : "Location"}
          ></Input>
        </div>

        {navigator.geolocation && (
          <Tooltip title="Search near me">
            <h3 className="pointer" onClick={getCurrentLocation} ><EnvironmentOutlined /></h3>
          </Tooltip>
        )}

        <Select
          onChange={(value) => setDuration(value)}
          className="w-100"
          size="large"
          placeholder="Duration"
        >
          <Option key={0}>{"Any"}</Option>
          <Option key={1}>{"15 minutes or less"}</Option>
          <Option key={2}>{"15 minutes to 30 minutes"}</Option>
          <Option key={3}>{"25 minutes to 1.25 hours"}</Option>
          <Option key={4}>{"1 hour to 2.5 hours"}</Option>
          <Option key={5}>{"2 hour to 4.5 hours"}</Option>
          <Option key={6}>{"4 hour to 6.5 hours"}</Option>
          <Option key={7}>{"4 hour to 8.5 hours"}</Option>
          <Option key={8}>{"Over 8 hours"}</Option>
        </Select>

        <Select
          onChange={(value) => setStorageType(value)}
          className="w-100"
          size="large"
          placeholder="Tour Type"
        >
          <Option key={0}>{"Any"}</Option>
          <Option key={1}>{"Walking, Urban"}</Option>
          <Option key={2}>{"Walking, Trail"}</Option>
          <Option key={3}>{"Walking, Historic"}</Option>
          <Option key={4}>{"Walking, Nature"}</Option>
          <Option key={5}>{"Accessible"}</Option>
          <Option key={6}>{"Biking, Urban"}</Option>
          <Option key={7}>{"Biking, Trail"}</Option>
          <Option key={8}>{"Biking, Historic"}</Option>
          <Option key={9}>{"Biking, Nature"}</Option>
          <Option key={10}>{"Water"}</Option>
          <Option key={11}>{"Skates, Hoverboard, Skateboard"}</Option>
          <Option key={12}>{"Electric Vehicle"}</Option>
          <Option key={13}>{"Power Vehicle"}</Option>
        </Select>

        <SearchOutlined
          onClick={handleSubmit}
          className="btn btn-primary p-3 btn-square"
        />
        <span className="w-25 ">&nbsp;</span>
      </div>

  </>    
  );
};

export default Search;
