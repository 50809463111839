import { useState } from "react";
import { useSelector } from "react-redux";
import { currencyFormatter } from "../../actions/stripe";
import { tourTypeString, durationStringMinutes, distanceString, givePromo } from "../../actions/hotel";
import { useHistory, Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined, ReadOutlined, PictureOutlined, TagOutlined, TagsFilled } from "@ant-design/icons";
import { TypeFeatures } from "../Helper";
import { Image, Card, Alert, Rate, Tooltip, Radio, DatePicker } from "antd";
import { Slideshow, SlideshowItem } from "../slideshow";
import { generateCoupon } from "../../actions/coupon";



const SmallCard = ({
  h,
  handleHotelDelete = (f) => f,
  owner = false,
  bought,
  showViewMoreButton = true,
}) => {
  const history = useHistory();
  const desc = ['No rating', 'Inaccurate', 'Poorly planned', "It's OK", 'This is good', 'Absolutely loved it'];
  const [showEmailPmpt, setShowEmailPmpt] = useState(false);
  const [showCouponPmpt, setShowCouponPmpt] = useState(false);
  const [promoResult, setPromoResult] = useState("Applying a Promo is non-revocable.");
  const [couponResult, setCouponResult] = useState("Not generated yet.");
  

  const [values, setValues] = useState({
    free2Email: "",
    c_numAvailable: 1,
    c_isPercent: false,
    c_isDiscount: false,
    c_isFree: true,
    c_amount: 0,
    c_beginDate: Date.now(),
    c_endDate: new Date("2100-1-1"),
  });
  // destructuring variables from state
  const {
    free2Email,
    c_numAvailable,
    c_isPercent,
    c_isDiscount,
    c_isFree,
    c_amount,
    c_beginDate,
    c_endDate
  } = values;

  const { RangePicker } = DatePicker;

  // redux
  const { auth } = useSelector((state) => ({ ...state }));

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeD = (e, es) => {
    let c_beginDate = new Date(e[0].year(), e[0].month() + 1, e[0].date());
    let c_endDate = new Date(e[1].year(), e[1].month() + 1, e[1].date());

    setValues({ ...values, c_beginDate: c_beginDate, c_endDate: c_endDate });
  };


  const onChangeR = (e) => {
    console.log(values);
    let c_isFree = false;
    let c_isDiscount = false;
    let c_isPercent = false;
    if (e.target.value == "free")
      c_isFree = true;
    if (e.target.value == "pct")
      c_isPercent = true;
    if (e.target.value == "dolr")
      c_isDiscount = true;
    setValues({ ...values, c_isFree: c_isFree, c_isPercent: c_isPercent, c_isDiscount: c_isDiscount });
  };

  const handleGiveFree = async (e) => {
    setPromoResult("Processing..");
    let res = await givePromo(auth.token, h._id, free2Email);
    setPromoResult(res.data.result);
    console.log("Result", res);
  };

  const handleGenCoupon = async (e) => {
    setCouponResult("Processing..");
    let res = await generateCoupon(auth.token, h._id, c_numAvailable, c_isPercent, c_isDiscount, c_isFree, false, c_amount);
    console.log("Result", res);
    setCouponResult(`Coupon Code: ${res.data._id}`);
  };

  function Photo(src) {
    //    console.log(src);
    return <img width="275" style={{ maxHeight: "305px", maxWidth: "300px" }} src={src.src}></img>;
  }


  //  console.log(h._id, showViewMoreButton);
  return (
    <>
      <Card hoverable className="card mb-3">
        <div className="row no-gutters">
          <div className="col-md-4">

            {h.image && h.image.contentType ? (
              <Slideshow width={100} height={100}>
                <SlideshowItem>
                  <Photo src={`${process.env.REACT_APP_API}/tour/image/${h._id}`} />
                </SlideshowItem>
                {h.image2 && h.image2.contentType ? (
                  <SlideshowItem>
                    <Photo src={`${process.env.REACT_APP_API}/tour/image2/${h._id}`} />
                  </SlideshowItem>
                ) : (<></>)}
                {h.image3 && h.image3.contentType ? (
                  <SlideshowItem>
                    <Photo src={`${process.env.REACT_APP_API}/tour/image3/${h._id}`} />
                  </SlideshowItem>
                ) : (<></>)}
              </Slideshow>
            ) : (
              <img
                src="/Placeholder400x400.png"
                alt="default site"
                width="260" style={{ maxHeight: "305px", maxWidth: "300px" }}
                className="card-image img img-fluid"
              />
            )}

          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h3 className="card-title">
                {h.title}{" "}
                <span className="float-right text-primary">
                  {currencyFormatter({
                    amount: h.price * 100,
                    currency: "usd",
                  })}
                </span>{" "}
              </h3>
              <p className="alert alert-info">{h.location}</p>
              <p className="card-text">{`${h.content.substring(0, 200)}...`}</p>
              <p className="card-text">
                This <span className="float-right text-primary"> {tourTypeString(h.layout)}{" "}
                </span>
                tour takes <span className="float-right text-primary">
                  {durationStringMinutes(h.duration)}{" "}
                </span>
                and spans <span className="float-right text-primary">
                  {distanceString(h.distance)}{" "}
                </span>
              </p>
              <p>
                Features
                {TypeFeatures(h)}

              </p>
              <p>{(h.bookedCount) ? (`Purchased ${h.bookedCount} ${(h.bookedCount === 1) ? "time" : "times"}`) : ""}</p>
              {h.rating > 0 && (<p>
                <Rate disabled count={5} value={h.rating} character={<PictureOutlined />} style={{ fontSize: 20 }} allowHalf />
                <span >&nbsp;{desc[Math.ceil(h.rating)]}</span>
              </p>)}
              {bought && (
                <Alert style={{ margin: "0 0 1em 0" }} type="success" message="You already have access to this Walking Tour" />
              )}
              <>
                {showViewMoreButton && (
                  <div className="d-flex h4">
                    <button
                      onClick={() => history.push(`/tour/${h._id}`)}
                      className="btn btn-primary"
                    >
                      Show more
                    </button>
                  </div>
                )}
                {owner && !h.isDeleted && (
                  <>
                    <div className="d-flex h6">
                      <Tooltip placement="top" title="Edit">
                        <Link to={`/tour/edit/${h._id}`} style={{ position: "absolute", height: "2em", margin: "-.3em 0 0 0" }}>
                          <EditOutlined className="text-warning" />
                        </Link>
                      </Tooltip>
                      <Tooltip placement="top" title="View">
                        <Link to={`/tour/${h._id}`} style={{ position: "absolute", height: "2em", margin: "-.3em 0 0 5em" }}>
                          <ReadOutlined className="text-success" />
                        </Link>
                      </Tooltip>
                      <Tooltip placement="top" title="Delete">
                        <DeleteOutlined style={{ position: "absolute", height: "2em", margin: "0 0 0 10em" }}
                          onClick={() => handleHotelDelete(h._id)}
                          className="text-danger"
                        />
                      </Tooltip>
                      <Tooltip placement="top" title="Give free access to user">
                        <TagOutlined style={{ position: "absolute", height: "2em", margin: "0 0 0 15em" }}
                          onClick={() => { setShowEmailPmpt((showEmailPmpt) ? false : true); setShowCouponPmpt(false); }}
                          className="text-primary"
                        />
                      </Tooltip>
                      <Tooltip placement="top" title="Create coupon code">
                        <TagsFilled style={{ position: "absolute", height: "2em", margin: "0 0 0 20em" }}
                          onClick={() => { setShowCouponPmpt((showCouponPmpt) ? false : true); setShowEmailPmpt(false); }}
                          className="text-success"
                        />
                      </Tooltip>
                    </div>
                    {(showEmailPmpt &&
                      <><br />
                        <input name="free2Email" placeholder="email address of user" value={free2Email} onChange={handleChange} />
                        <label className={(free2Email.length > 5) ? 'btn btn-outline-primary btn-block m-2' : 'btn btn-outline-disabled btn-block m-2'}
                          onClick={handleGiveFree}
                        >Give Promo Copy</label>
                        <p>{promoResult}</p>
                      </>
                    )}
                    {(showCouponPmpt &&
                      <><br />
                      Number issued <input name="c_numAvailable" placeholder="number available" value={c_numAvailable} onChange={handleChange} /><br />
                        <Radio.Group onChange={onChangeR} defaultValue="free">
                          <Radio.Button value="free">Free</Radio.Button>
                          <Radio.Button value="pct">% Discount</Radio.Button>
                          <Radio.Button value="dolr">$ Discount</Radio.Button>
                        </Radio.Group><br />
                      Amount <input type="number" min="1" name="c_amount" placeholder="amount" value={c_amount} disabled={c_isFree} onChange={handleChange} /><br />
                      <RangePicker onChange={onChangeD} /><br />
                        <label className={(c_numAvailable > 0) ? 'btn btn-outline-primary btn-block m-2' : 'btn btn-outline-disabled btn-block m-2'}
                          onClick={handleGenCoupon}
                        >Generate Coupon</label>
                        <p>{couponResult}</p>
                      </>
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

/*
            {h.image2 && h.image2.contentType ? (
              <img
                src={`${process.env.REACT_APP_API}/hotel/image2/${h._id}`}
                alt="default hotel image"
                className="card-image img img-fluid"
              />
            ) : ( <></> )}            
            {h.image3 && h.image3.contentType ? (
              <img
                src={`${process.env.REACT_APP_API}/hotel/image3/${h._id}`}
                alt="default hotel image"
                className="card-image img img-fluid"
              />
            ) : ( <></> )} 
*/

export default SmallCard;
