import axios from "axios";

export const generateCoupon = async (token, tourId, numAvailable, isPercent, isDiscount, isFree, isAuthorwide, amount) => 
    await axios.post(`${process.env.REACT_APP_API}/create-coupon`, {
      description: String,
      revoked: Boolean,
      beginDate: Date.now(),
      endDate: new Date("2100-1-1"),
      numAvailable: numAvailable,
      numRedeemed: 0,
      isPercent: isPercent,
      isDiscount: isDiscount,
      price: (isFree) ? 0 : amount,
      hotelId: tourId,
      authorId: isAuthorwide,
    }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

