import { Result, Button, Card, Tag } from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { allSites, userTourBookedList } from "../actions/hotel";
import { Player } from '@lottiefiles/react-lottie-player';
import SmallCard from "../components/cards/SmallCard";
import Search from "../components/forms/Search";
import { isUserUsingMobile } from "../components/Helper";
import { ShopTwoTone, MobileTwoTone, WifiOutlined, GlobalOutlined, QrcodeOutlined, DisconnectOutlined, CloudDownloadOutlined } from "@ant-design/icons";


const Home = () => {
  const [sites, setSites] = useState([]);
  const [mySites, setMySites] = useState([]);
  const { auth } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadAllsites();
    // eslint-disable-next-line
  }, []);

  const animList = [
    "54564-girl-on-the-beach",
    "57997-travelers-find-location",
    "23518-usa-empty-state",
    "23520-canada-empty-state",
    "14494-plane-flying",
    "11428-travel-icons-mountain",
    "31848-footprint",
    "106088-guide-avec-boussole",
    "121275-binoculars",
    "128709-earth",
    "90724-travel-world-usa",
    "73387-tourist-travel",
    "8194-sea-turtle",
    "84875-world-map-pinging-and-searching",
    "121264-bag-pack",
    "121265-diving",
    "121267-location",
    "129402-location",
    "64376-maps",
    "108262-rota-mapa",
    "21692-direction",
    "47956-area-map",
    "103681-map-directions-colorful"
  ];

  const loadAllsites = async () => {
    let res = await allSites();
    console.log(res.data);

    console.log("AUTH ===>", auth);
    if (auth && auth.token) {
      userTourBookedList(auth.token).then((res2) => {
        console.log("SEARCH Booked RESULTS ===>", res2.data);
        //emps.findIndex(item => item.name == 'eric')
        setMySites(res2.data);
      });
    }
    setSites(res.data);
  };

  const hasPurchased = (hotelId) => {
    return (mySites.findIndex(item => item.hotel === hotelId) === -1) ? false : true;
  }

  let currentAnim1 = () => {
    var seconds = Math.floor(new Date().getTime() / 5000);
    console.log("Seconds", seconds);
    var rand = seconds % animList.length;
    console.log("Rand", rand);
    return `/anims/${animList[rand]}.json`

  }

  let animStr = currentAnim1();


  const getMobilePrompt = () => {
    if (isUserUsingMobile()) {
      return (<h2>Ready to take your tour? <a href="/mobile.html">Tap Here.</a></h2>)
    } 
    return (<></>);
  }
  
  return (
    <>
      {getMobilePrompt()}
      <div style={{backgroundImage:"url(/backgrounds/niagarafalls.jpg)", backgroundSize:"100% 110%"}} className="container-fluid bg-secondary p-5 text-center">
        <h1 className="page-title"><img alt="logo" src="/logo192.png" />Walkingtour.com - Your Social Tourguide</h1> 
      </div>
      <div className="col mainpage">
        <br />


        <Result
          status="warning"
          title="We have no identity!"
          subTitle="We're not live. We're waiting on funding to style the site, and then go live."
          extra={[
            <Button type="disabled" key="console"
            onClick={() => alert("We're not ready yet, but please come back later.")}
            >Fund Us
            </Button>,
          ]}
        />

        <br />
        <Player
            autoplay
            loop
            src={animStr}
            style={{ height: '300px', width: '300px' }}
          >
        </Player>
        <h2><center>Going on a trip and want a unique cultural expereince?</center></h2>
        <h3><center>You know the area and want to play digital tourguide, and maybe make some cash?</center></h3>
        <br />
        <Card hoverable style={{width:"80%", margin:"auto"}}>
          <p><b>
            <b>To use the tours:</b>
            <ul>
              <li>Find and purchase <span style={{ fontSize: '1.3rem' }}> <ShopTwoTone /></span> your perfect next tour below</li>
              <li>Choose <a href="/dashboard/upload">Dashboard</a> in the page banner</li>
              <li>Choose which tours to take with you <span style={{ fontSize: '1.3rem' }}> <CloudDownloadOutlined /></span> this trip by selecting <Tag color="cyan">Download to Device</Tag>or <Tag color="red">Remove from Download</Tag></li>
              <li>Scan the QR Code <span style={{ fontSize: '1.3rem' }}> <QrcodeOutlined /></span> on your phone <span style={{ fontSize: '1.3rem' }}> <MobileTwoTone /></span> to enter tour mode</li>
              <li>All tours are available and operational in both connected <span style={{ fontSize: '1.3rem' }}> <WifiOutlined /></span> and offline <span style={{ fontSize: '1.3rem' }}> <DisconnectOutlined /></span> modes</li>
            </ul>
          </b></p>
          <p>You're visiting a new vacation spot or just some personal time on a working trip. Download an offline walking tour and follow along a guided tour on your phone.</p>
          <p>Locals and experts have 'recorded' their own walk, made notes, attached photos and uploaded it just for you.</p>
          <p>Since they recorded their own trip, the tour includes real timing, distances, and paths that actually exist. Google may have great maps, but it doesn't know everything when it comes to walking and seeing the sights.</p>
          <p>Log in now and search for the perfect tour.  When you're ready to leave, download the entire tour for use offline -- even if your tour takes you to somewhere so exotic there is no wireless to be found, you'll still find your way.</p>
        </Card>
      </div>
      <div className="col mainpage">
        <br />
        <Search />
      </div>
      <div className="container-fluid mainpage">
        <br />
        {/* <pre>{JSON.stringify(sites, null, 4)}</pre> */}
        {sites.map((h) => (
          <SmallCard bought={hasPurchased(h._id)} key={h._id} h={h} showViewMoreButton={true} />
        ))}
      </div>
    </>
  );
};

export default Home;

/*
        <Result
          status="success"
          title="We have no identity!"
          subTitle="We're waiting on funding to name and style the site, and then go live."
          extra={[
            <Button type="disabled" key="console"
            onClick={() => window.location="http://www.goldencrater.com"}
            >Fund Us
            </Button>,
          ]}
        />
*/