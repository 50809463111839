import React, { Component } from 'react';
import { Map, Draggable, ZoomControl } from 'pigeon-maps'
import { MapLine } from './MapLine'



export class MapControl extends Component {
    constructor(props) {
        super(props)
        this.handleBoundsChange = this.handleBoundsChange.bind(this);
    }

    wa = (e, t, o) => window.addEventListener(e, t, o)
    wr = (e, t) => window.removeEventListener(e, t)

    zoomIn = () => {
        this.setState({
            zoom: Math.min(this.state.zoom + 1, 18)
        })
    }

    zoomOut = () => {
        this.setState({
            zoom: Math.max(this.state.zoom - 1, 1)
        })
    }

    mapTilerProvider = function (x, y, z, dpr) {
        if (z <= process.env.REACT_APP_SERVER_CACHED_ZOOM * 1.0) {
            return `${process.env.PUBLIC_URL}/maptiles/${z}/${x}-${y}.png`
        } else {
            const s = String.fromCharCode(97 + (x + y + z) % 3)
            return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`
        }
    }


    handleBoundsChange = ({ center, zoom, bounds, initial }) => {
        if (initial) {
            console.log('Got initial bounds: ', bounds)
        }
        if (this.props.onBoundsChanged) 
            this.props.onBoundsChanged({ center, zoom, bounds, initial })

        this.setState({ center, zoom })
    }

    handleClick = ({ event, latLng, pixel }) => {
        console.log('Map clicked!', latLng, pixel)
    }

    handleMarkerClick = ( event, payload, anchor, contextClicked ) => {
        console.log(`Marker #${payload} clicked at: `, anchor)
    }

    handleAnimationStart = () => {
        this.setState({ animating: true })
    }

    handleAnimationStop = () => {
        this.setState({ animating: false })
    }

    LineStyle = { stroke: 'red', strokeWidth: 4, strokeLinecap: "round", strokeOpacity: 0.4 };
    LineStyle2 = { stroke: 'blue', strokeWidth: 2, strokeLinecap: "round" };



    render() {
        const { center, zoom, markers, pathlines, routelines, minZoom, maxZoom, onClick, onContextMenu, onMarkerClick, onMarkerContextClick, onDragPinStart, onDragPinMove, onDragPinEnd, width, height, maxWidth, maxHeight } = this.props

//        console.log('************ here 1 ' + JSON.stringify(this.props));


        return (

    <div style={{ maxWidth: { maxWidth }, width: { width }, maxHeight: { maxHeight }, height: { height }, margin: '0 auto' }}>
            <Map
                provider={this.mapTilerProvider}
                limitBounds='edge'
                onBoundsChanged={this.handleBoundsChange}
                onClick={onClick}
                onContextMenu={onContextMenu}
                onAnimationStart={this.handleAnimationStart}
                onAnimationStop={this.handleAnimationStop}
                animate={true}
                metaWheelZoom={false}
                twoFingerDrag={true}
                zoomSnap={true}
                mouseEvents={true}
                touchEvents={true}
                center={center}
                zoom={zoom}
                dprs={[1, 2]}
                minZoom={minZoom}
                maxZoom={maxZoom}
                defaultWidth={maxWidth}
                defaultHeight={maxHeight}
                height={height}
                width={width}
                    boxClassname="pigeon-filters">
                    {(pathlines) ? <MapLine coordsArray={pathlines} style={this.LineStyle} /> : ""}
                    {(routelines) ? <MapLine coordsArray={routelines} style={this.LineStyle2} /> : ""}
                    {markers && markers.map(key => (
                        <Draggable key={key[6]} anchor={key[1]} offset={[8, 8]} payload={key}
                            onDragStart={() => onDragPinStart({ key }, key[1])}
                            onDragMove={(anchor) => onDragPinMove(anchor, { key }, key[1])}
                            onDragEnd={(anchor) => onDragPinEnd(anchor, { key }, key[1])} >
                            <img src={key[3]} width={17} height={17} alt='' style={{ display: 'block' }}
                                onClick={(event, payload) => onMarkerClick(event, { key }, key[1])}
                                onContextMenu={(event, payload) => onMarkerContextClick(event, { key }, key[1])} />
                        </Draggable>
                    ))}
                    <ZoomControl />
                </Map>
        </div>
    )
    }

}

